<template>
  <div class="fotoCmpMain" :class="{'spacerRowTop24': (cmp.state===1) ? true : false }">
    <div class="fotoCmpMain_inputZone" style="display:none;"> <!-- Zona donde se almacena la photo FileSelect -->
        <!-- Zona de input q recibe el archivo ya sea x seleccion o por DD -->
      <input type="file" 
        :id="'pac_fileinputx_' + internalId"
        :name="'pac_fileinputx_' + internalId" 
        :accept="fxu_ConfigGeneral.acceptStr" 
        style="display:none;"
      ><!-- @change="imgxfilex_onChangeFileToUse($event)" -->
    </div>
    <template v-if="(cmp.state===0 && uploadingState===false)">
      <div :id="'pac_' + internalId + '_initialFileReq'" class="" style="">
        <div class="noselect filePreviewBox filePreviewUploadBox spacerRowTop24">
          <div class="filePreviewUploadBox_container">
            <div v-show="(uploadingState===true)?false:true">
                <div :id="'pac_photoUploader_' + internalId" class="uploaderFile_main uploaderFile_main_photo" style="margin: 0px;"
                  v-show="(cmp.state_fileReq==='camera') ? false : true "
                >
                  <div class="ufx_dropfileszone"></div> <!-- Este recibe el drop --> 
                    <div class="uploaderFile_main_contents">
                      <div class="main_title" style="margin-top: 30px;padding: 0px 25px;">
                        <template v-if="options_allowDD===false">
                          {{options_uploadTextNO_DD_Legend}}
                        </template>
                        <template v-else>
                          {{options_uploadTextLegend}}
                        </template>
                        <!-- Arrastra y suelta aquí una imagen o elige un archivo de tu dispositivo. -->
                      </div>
                      <div class="zona_button" style="margin-top: 24px; padding: 0px 25px;display: flex;align-items: center;justify-content: center;">
                          <q-btn type="button" class="" color="app_mainBtn" 
                            unelevated :label="'Elegir archivo'" 
                            @click.stop="imgxfilex_doSelectFile($event)"
                            :disable="(uploadingState === true) ? true :false"
                          />
                      </div>
                      <div class="formats_title" style="margin-top: 24px; margin-bottom: 16px; padding: 0px 25px;">
                        {{options_uploadTextFormats}} <!-- JPG, PNG -->
                      </div>

                      <template v-if="zhasMedia_Support===true && zhasMedia_Devices===true">
                        <template v-if="isSafariIos_client===false">
                          <div class="zona_button" style="margin-top: 24px; margin-bottom: 24px; padding: 0px 25px;display: flex;align-items: center;justify-content: center;">
                            <q-btn type="button" class="" color="app_mainBtn" 
                              unelevated :label="'Tomar foto'" 
                              @click.stop="imgxfilex_doStartCamera($event)"
                            />
                          </div>
                        </template>
                      </template>

                      <template v-if="hasOtherRecord===true">
                        <div class="zona_button" style="margin-top: 10px; margin-bottom: 16px; padding: 0px 25px;display: flex;align-items: center;justify-content: center;">
                            <div class="action_item doc_uploadLink" @click.stop="abortAny_Upload">
                              Restablecer imagen
                            </div>
                        </div>
                      </template>

                    </div>
                </div>

                <div :id="'pac_photoCaptureFrame_' + internalId" style="margin: 0px; display: flex;position: relative;" v-if="cmp.state_fileReq==='camera'">
                  <div :style="(is_Mobile || $q.screen.lt.sm)? 'width:100%;':''">
                    <div id="pac_photoCapture_borderFrame" class="uploaderFile_main_cameraBorder">
                      <div id="pac_photoCapture_fotoZone" class="uploaderFile_main_cameraZone">
                        <template v-if="cameraData.errors===true">
                          <div style="padding: 20px;display: flex;align-items: center;">
                            <div class="main_title" v-html="cameraData.errorString"></div>
                          </div>
                        </template>
                        <video :id="'pac_video_'  + internalId" class="uploaderFile_main_videoel video_Elx"
                          :style="(cameraData.errors===true)?'display:none !important;':''"
                        ></video>
                        <canvas :id="'pac_cameracanvas_'+ internalId"  style="display:none;"
                          :style="(cameraData.errors===true)?'display:none !important;':''"
                        ></canvas>
                        <img :id="'pac_photocamera_'+ internalId" style="display:none;">
                        <!-- title="The screen capture will appear in this box." -->
                      </div>
                    </div>
                    <div class="pac_photoCapture_toolbarZone" style="display: flex;justify-content: space-evenly;margin-top: 16px;">
                      <q-btn type="button" class="" color="app_mainBtn" unelevated :label="'Cancelar'" @click.stop="imgxfilex_doCancelCamera($event)"/>
                      <div v-if="cameraData.errors===false">
                        <template v-if="(cameraData.flagTaken===false)">
                          <q-btn type="button" class="" color="app_mainBtn" icon="photo_camera" round  unelevated 
                            @click.stop="imgxfilex_doCameraTakePicture($event)"
                          />
                        </template>
                        <template v-else>
                            <q-btn type="button" class="" color="app_mainBtn" icon="check" round  unelevated 
                              @click.stop="imgxfilex_doCameraAcceptImage($event)"
                            />
                            <q-btn type="button" class="" color="app_mainBtn" icon="clear" round  unelevated  style="margin-left: 24px;"
                              @click.stop="imgxfilex_doCameraCancelImage($event)"
                            />
                        </template>
                      </div>
                    </div>
                  </div>

                </div>

                <!-- Zona de errores si se presentan -->
                <template v-if="this.image_fileErrors.zonaFile===true && this.image_fileErrors.zonaFile_msg!==''">
                  <div 
                    class="canna_frmMainErrorZone uploaderFile_mainErrorZone spacerRowTop20" 
                    style="display: flex;justify-content: center;align-items: center; margin-bottom:20px;"
                  >
                    <div style="margin-right:10px;">
                      <img src="images/login_warning.png"/>
                    </div>
                    <div style="flex:1;"  v-html="this.image_fileErrors.zonaFile_msg"></div>
                  </div>
                </template>

            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- Editor --> 
    <div :id="'pac_' + internalId + '_editorState'" class="fotoCmpMain_editor" style="" 
      v-show="(cmp.state===1 && uploadingState===false)"
    >
        <div class="cropper-area">
          <div class="img-cropper" v-show="imgzData.imgSrc!=='' && imgzData.imgSrc!==null">
            <vue-cropper
              ref="cropperzz"
              :containerStyle="{
                'font-size':'inherit'
              }"
              :alt="'Imagen a utilizar'"
              :src="imgzData.imgSrc"
              :drag-mode="cropperSettings.dragMode"
              :aspect-ratio="cropperSettings.aspectRatio"
              :responsive="cropperSettings.responsive"
              preview=".preview"
            >
            </vue-cropper>
          </div>
          <div class="img-cropper-actions spacerRowTop16">
            
            <div class="actionBar_a" >

              <q-icon :size="(is_Mobile || $q.screen.lt.sm) ? 'md' :'md'" 
                class="action_item actionEditor_Icon"
                color="cannaMainColor"
                name="fas fa-arrows-alt" @click.stop="cpx_setDragMode('move')" 
                :style="'font-size:22px !important;'"
              >
                <q-tooltip anchor="top middle" self="center middle">Mover</q-tooltip>
              </q-icon>

              <q-icon :size="(is_Mobile || $q.screen.lt.sm) ? 'md' :'md'" 
                class="action_item actionEditor_Icon"
                color="cannaMainColor"
                name="fas fa-crop" @click.stop="cpx_setDragMode('crop')" 
                :style="'font-size:22px !important;'"
              >
                <q-tooltip anchor="top middle" self="center middle">Recortar</q-tooltip>
              </q-icon>


              <q-icon :size="(is_Mobile || $q.screen.lt.sm) ? 'md' :'md'" 
                class="action_item actionEditor_Icon"
                color="cannaMainColor"
                name="zoom_in" @click.stop="cpx_zoom(0.2)" 
              >
                <q-tooltip anchor="top middle" self="center middle">Agrandar imagen</q-tooltip>
              </q-icon>
              <q-icon :size="(is_Mobile || $q.screen.lt.sm) ? 'md' :'md'" 
                class="action_item actionEditor_Icon"
                color="cannaMainColor"
                name="zoom_out" @click.stop="cpx_zoom(-0.2)" 
              >
                <q-tooltip anchor="top middle" self="center middle">Reducir imagen</q-tooltip>
              </q-icon>

              <!-- Rotar imagen -->
              <q-icon :size="(is_Mobile || $q.screen.lt.sm) ? 'md' :'md'" 
                class="action_item actionEditor_Icon"
                color="cannaMainColor"
                name="rotate_left" @click.stop="cpx_rotate(-45)" 
              >
                <q-tooltip anchor="top middle" self="center middle">Rotar a la Izquierda</q-tooltip>
              </q-icon>
              <q-icon :size="(is_Mobile || $q.screen.lt.sm) ? 'md' :'md'" 
                class="action_item actionEditor_Icon"
                color="cannaMainColor"
                name="rotate_right" @click.stop="cpx_rotate(45)" 
              >
                <q-tooltip anchor="top middle" self="center middle">Rotar a la derecha</q-tooltip>
              </q-icon>

              <!-- voltear imagen -->
              <div style="display:none;" ref="flipX"></div>
              <div style="display:none;" ref="flipY"></div>
              <q-icon :size="(is_Mobile || $q.screen.lt.sm) ? 'md' :'md'" 
                class="action_item actionEditor_Icon"
                color="cannaMainColor"
                name="swap_horiz" @click.stop="cpx_flipx" 
              >
                <q-tooltip anchor="top middle" self="center middle">Voltear horizontalmente</q-tooltip>
              </q-icon>
              <q-icon :size="(is_Mobile || $q.screen.lt.sm) ? 'md' :'md'" 
                class="action_item actionEditor_Icon"
                color="cannaMainColor"
                name="swap_vert" @click.stop="cpx_flipY" 
              >
                <q-tooltip anchor="top middle" self="center middle">Voltear verticalmente</q-tooltip>
              </q-icon>

            </div>

            <div class="actionBar_b">
              <q-icon :size="(is_Mobile || $q.screen.lt.sm) ? 'md' :'md'" 
                class="action_item actionEditor_Icon"
                color="cannaMainColor"
                name="restart_alt" @click.stop="cpx_resetImage" 
              >
                <q-tooltip anchor="top middle" self="center middle">Resetear imagen</q-tooltip>
              </q-icon>

              <q-icon :size="(is_Mobile || $q.screen.lt.sm) ? 'md' :'md'" 
                class="action_item actionEditor_Icon"
                color="cannaMainColor"
                name="delete_forever" @click.stop="cpx_reset" 
              >
                <q-tooltip anchor="top middle" self="center middle">Cancelar imagen</q-tooltip>
              </q-icon>

              <q-icon :size="(is_Mobile || $q.screen.lt.sm) ? 'md' :'md'" 
                class="action_item actionEditor_Icon"
                color="cannaMainColor"
                name="done" @click.stop="cpx_DoCropImage" 
              >
                <q-tooltip anchor="top middle" self="center middle">Establecer cambios en imagen</q-tooltip>
              </q-icon>

              <q-icon :size="(is_Mobile || $q.screen.lt.sm) ? 'md' :'md'" 
                class="action_item actionEditor_Icon"
                color="cannaMainColor"
                name="help_outline" @click.stop="doShow_HelpModal" 
              >
                <q-tooltip anchor="top middle" self="center middle">Ayuda del editor</q-tooltip>
              </q-icon>

            </div>

          </div>
          
          <div v-show="(z_CropPreview)">
            <div>Vista PRevia</div>
            <div class="preview"></div>
          </div>

        </div>
    </div>

    <!-- Paso final, revision final de imagen antes de subirse -->
    <div
      :id="'pac_' + internalId + '_imageDocumentDone'" 
      class="spacerRowTop24" style=""
      v-show="(cmp.state===2 && uploadingState===false && imgzData.cropImg)"
    >
      <div class="dataSection_title noselect" style="margin-bottom: 20px;">Imagen Final (revisión)</div>
      <div class="cropped-image">

        <template v-if="imgzData.cropImg">
          <img :src="imgzData.cropImg" alt="Imagen Final" style="border: 1px solid #F1F1F4;"/>
        </template>
        <template v-else>
          <div class="crop-placeholder"></div>
        </template>

        <div class="img-cropper-actions_final spacerRowTop16">
          <div style="display:none;">
            <a :id="'avatarfinal_' + internalId" target-ref="a_1" :download="imgzData.myDownloadFileName" style="display:none;"/>
          </div>
          <div class="actionBar">

              <q-icon :size="(is_Mobile || $q.screen.lt.sm) ? 'md' :'md'" 
                class="action_item actionEditor_Icon actionIconFinal"
                color="cannaMainColor"
                name="block" @click.stop="cpxb_Cancel" 
              >
                <q-tooltip anchor="top middle" self="center middle">Regresar al editor</q-tooltip>
              </q-icon>

              <q-icon :size="(is_Mobile || $q.screen.lt.sm) ? 'md' :'md'" 
                class="action_item actionEditor_Icon actionIconFinal"
                color="cannaMainColor"
                name="download" @click.stop="cpxb_Download" 
              >
                <q-tooltip anchor="top middle" self="center middle">Descargar</q-tooltip>
              </q-icon>

              <q-icon :size="(is_Mobile || $q.screen.lt.sm) ? 'md' :'md'" 
                class="action_item actionEditor_Icon actionIconFinal"
                color="cannaMainColor"
                name="save" @click.stop="cpxb_SaveSubmit" 
              >
                <q-tooltip anchor="top middle" self="center middle">Guardar imagen final</q-tooltip>
              </q-icon>

              <q-icon :size="(is_Mobile || $q.screen.lt.sm) ? 'md' :'md'" 
                class="action_item actionEditor_Icon"
                color="cannaMainColor"
                name="help_outline" @click.stop="doShow_HelpModal" 
              >
                <q-tooltip anchor="top middle" self="center middle">Ayuda del editor</q-tooltip>
              </q-icon>

          </div>

        </div>
      </div>
    </div>

    <formPhotoHelp
      ref="frmHelpModal"
      :actionForm="'help'"
      v-on:doCloseDialog="doCloseHelpModal"
    ></formPhotoHelp>

    <!-- Control para subir archivo --> 
    <div v-if="(uploadingState===true)">
      <q-spinner :size="(pageSize === 'sm' || pageSize === 'xs') ? '60px' : '100px' " color="cannaLoading" :thickness="10"/>
    </div>

  </div>
</template>
<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css'

import logOut_Mixin from '../../mixins/requestMix.js'
import myplatform_Mixin from '../../mixins/platformMix.js'

export default {
  name:'photo_avatar_component',
  props: {
		autoStart: { //SP10 debido a permisos de acceso 
			type: Boolean, 
			default: false 
		},
    initialState:{
			type: Number, 
			default: 0
    },
    internalId: {
      type: String,
      required: true,
      default: ''
    },
		options:{
			type: Object ,
			default: function () {
				return { ap_Default:true };
			}
		},
    hasOtherRecord:{
			type: Boolean,
			default: false
    },
		recordImage:{
			type: Object ,
			default: function () {
				return null; // { imageTest:'none' };
			}
		},
    options_allowDD:{
			type: Boolean,
      required: true,
			default: true
    },
    options_uploadTextLegend: {
      type: String,
      required: false ,
      default: 'Arrastra y suelta aquí una imagen o elige un archivo de tu dispositivo.'
    },
    options_uploadTextNO_DD_Legend : {
      type: String, 
      required: false ,
      default: 'Elige un archivo de tu dispositivo.'
    },
    options_uploadTextFormats: {
      type: String, 
      required: false , 
      default: 'JPG,JPEG, PNG'
    }
  },
  components: { 
    'vue-cropper': VueCropper,
    formPhotoHelp: require('components/photo/photoAvatar_help.vue').default 
  },
  mixins:[
    logOut_Mixin,
    myplatform_Mixin
  ],
  data () {
    return {
      version: '1.0.1',
      debugEls: false,
      //-------------
      zinitData:false,
      z_initDD_Events:false,
      zinitCmp: false,
      zloadingData: false,
      z_CropPreview: false, 
      loading_Process: false,
      processingData: false,
      uploadingState:false, // PAra controlar cuando se esta haciendo el upload 
      frm_helpFrmShow: false,
      //-------------
      zhasMedia_Support: false,
      zhasMedia_Devices: false, // este es para checar si hay dispositivo de camara video 
      cameraData:{
        width: 440,
        errors: false,
        errorString: '',
        data: null, 
        dataBlob:null,
        streaming:false ,
        streamEl:null,
        videoEl:null, 
        canvasEl:null,
        flagTaken: false ,
        photoEl:null
      },
      //-------------
      cmp:{
        state: 0 ,
          // 0 == request file VIEW 
          // 1 == image selected (editor mode)
          // 2 == file uploaded & showcase (image document)
        state_fileReq: 'file' // 'file' or 'camera'
      },
      imgzData:{ 
        myDownloadFileName:'',
        imgFileName:'',
        imgFileType:'',
        imgSrc: '', // images/login_warning.png
        cropImg: '',
        data: null,
        dataBlob:null
      },
      imgxData: {
        cropped: false,
        cropping: false,
        loaded: false,
        name: '',
        previousUrl: '',
        type: '',
        url: '',
      },
      image_fileErrors: {
        zonaFile: '',
        zonaFile_msg: '',
        zonaBFile: '',
        zonaBFile_msg: ''
      },
      //-------------
      fxu_ConfigGeneral:{
        maxFiles: 1,
        maxFileSize: 5242880 , //5mb  // 31457280, //30mb  ---- //Size en bytes  //1mb = 1048576   //5mb 5242880
				fileFormats: "jpg,jpeg,png", // /(.*?)\.(jpg|jpeg|png|pdf)$/
				acceptStr: 'image/jpeg,image/png'
      },
      cropperSettings:{
        dragMode:'none',
        responsive: true,
        aspectRatio: (1 / 1),
      }
    }
  },
  computed:{
    isSafariIos_client(){
      return  (this.$q.platform.is.ios && this.$q.platform.is.safari ) ? true : false 
    }
  }, 
  watch:{
    hasOtherRecord: function( newValue, oldValue ){ 
      if (newValue !== oldValue){ 
        this.$forceUpdate()
      }
    },
    options_allowDD: function (newValue, oldValue ){ 
      if (newValue !== oldValue){ 
        this.$forceUpdate()
      }
    }
  },
  methods:{
    do_resetComponent( infoObj ){
      // Se resetea el control al Estado q indique  en el infoObj 
    },
    doSet_RecordPhoto () { 
      // Se establece el registro de la FOTO...!
      this.$forceUpdate()
    }, 
    abortAny_Upload (){ 
      this.$cannaDebug('-- photoComp -- abort Any _ Upload')
      this.$emit('doAbortChangePhoto', { close: true  })
    },
    //----------------
    doCloseHelpModal(payload){
      this.$refs.frmHelpModal.doHide()
      this.$forceUpdate()
    },
    doShow_HelpModal(){
      this.$refs.frmHelpModal.doShow()
    },
    //----------------
    //DD events 
    cmpx_initDragDropEvents () { 
      if (this.z_initDD_Events ===true ){
        return false
      }
      // A inicializar los DD events para el componente..! 
      var self = this, target = null, events='';
      //-------------
      var mainELName = 'pac_photoUploader_' + this.internalId + ''
      var mainInputElName = 'pac_fileinputx_' + this.internalId
      //-------------
      var mainEl = document.getElementById(mainELName);
      //-------------
      events='drag,dragstart,dragend,dragover,dragenter,dragleave'.split(',')
      events.forEach(function( event, index, arrayEvents ){
          mainEl.addEventListener(event, function (e){ 
            e.stopPropagation();
            e.preventDefault();
            if (self.options_allowDD===false){ // if (self.hasRecord==true || self.editMode==true){  //Cambios especiales para modo edicion 
              e.dataTransfer.dropEffect = 'none'
            } else { 
              e.dataTransfer.dropEffect = 'copy'
            }
          })
      })
      // Register the events when the file is out or dropped on the dropzone  
      events='dragend,dragleave'.split(',')
      events.forEach(function( event, index, arrayEvents ){
          mainEl.addEventListener(event,function (e) {
              if (target === e.target) {
                if (self.options_allowDD===false){ // if (self.hasRecord==true || self.editMode==true){ // nothing to do
                  console.log('no se hace nada -- mainEl -- dragend,dragleave')
                } else {
                  mainEl.classList.remove('is-dragover')
                }
              }
          })
      })
      //Register the events when the file is over the dropzone
      events='dragover,dragenter'.split(',');
      events.forEach(function( event, index, arrayEvents ){
          mainEl.addEventListener(event,function (e) {
            target = e.target;
            if (self.options_allowDD===false){ // nothing to do  //if (self.hasRecord==true || self.editMode==true){ 
              console.log('no se hace nada -- mainEl -- dragover,dragenter')
            } else {
              mainEl.classList.add('is-dragover')
            }
          });
      })
      // On a file is selected, calls the readFile method. It is allowed to select just one file - we're forcing it here.
      var inputFileEl = document.getElementsByName(mainInputElName)[0]
      inputFileEl.addEventListener('change', function (e) {
        if (this.files && this.files.length) {
            self.imgxfilex_onChangeFileToUse (this.files) // self.readFile(this.files);
            // self.prevEditFlag = false; //Se limpia edit flag
        }
      })
      //Register the drop element to the container component
      mainEl.addEventListener('drop', function (evt) {
        evt.stopPropagation();
        evt.preventDefault();
        if (self.options_allowDD===false){ // nothing to do
          console.log('no se hace nada -- mainEl -- drop');
        } else {
          mainEl.classList.remove('is-dragover');
          var files = evt.dataTransfer.files;
          // files is a FileList of File objects. List some properties.
          var output = [];
          for (var i = 0, f; f = files[i]; i++) {
            output.push('', escape(f.name), '', f.type || 'n/a', ') - ', 
              f.size, ' bytes, last modified: ',
              f.lastModifiedDate ? f.lastModifiedDate.toLocaleDateString() : 'n/a','');
          }
          var salidax = '' + output.join('') + '';
          console.log('' + salidax);
          self.imgxfilex_onChangeFileToUse (files) // self.readFile(files);
        }
      });

      this.z_initDD_Events = true
    },
    //-----------------
    imgxfilex_doSelectFile (event ){
      var inputId = 'pac_fileinputx_' + this.internalId   // :id="'pac_fileinputx_' + internalId"
      document.getElementById(inputId).click();
    },
    imgxfilex_onChangeFileToUse ( files ){ 
      this.$cannaDebug('-- dashMainScreen -- img x filex_  on ChangeFileToUse')
      // $event, 1, docItem
      var test = 11
      var self = this 
      //var fileDoc =  event.target.files[0]
      var tipoInput = 1

      if (files.length > 1 || files.length<= 0 ){   //if (files.length > self.options.maxFiles){  
        var baseErrnumStr = 'El número de archivos permitido es invalido, solo se acepta{0} {1} archivo{2}'
        strMsg = baseErrnumStr;
        strMsg = strMsg.replace('{0}', (self.fxu_ConfigGeneral.maxFiles==1)?'':'n' ).replace('{1}', self.fxu_ConfigGeneral.maxFiles ).replace('{2}', (self.fxu_ConfigGeneral.maxFiles==1)?'':'s'); 
        this.image_fileErrors.zonaFile_msg = strMsg
        this.image_fileErrors.zonaFile = true
        this.$forceUpdate()
        return false
      } else { 
        this.image_fileErrors.zonaFile_msg = ''
        this.image_fileErrors.zonaFile = false
      }

      var fileDoc =  files[0]
      //-- Se valida la(s) extension(es) de el/los archivos
      var validFormats=true
      var myreg = '(.*?)\.(' + this.fxu_ConfigGeneral.fileFormats.replace(/\,/g,'|') + ')$'
      var tReg = new RegExp(myreg)
      var testName = fileDoc.name.toLowerCase()
      var validFormats = tReg.test(testName)
      //validamos peso
      var validFileSize = ( fileDoc.size <= this.fxu_ConfigGeneral.maxFileSize ) ? true:false
      var validFilex = false
      //------------------------------
      if (validFormats===false){ // RARO peropuede pasar 
        if (tipoInput === 1){ 
          this.image_fileErrors.zonaFile_msg = 'Al parecer tu imagen no tiene el formato permitido'
          this.image_fileErrors.zonaFile = true
        } else { 
          this.image_fileErrors.zonaBFile_msg = 'Al parecer tu imagen no tiene el formato permitido'
          this.image_fileErrors.zonaBFile = true
        }
        this.$forceUpdate()
        return false
      }
      if (validFileSize===false){ 
        if (tipoInput === 1){ 
          this.image_fileErrors.zonaFile_msg = 'Al parecer tu imagen pesa mas de lo permitido'
          this.image_fileErrors.zonaFile = true
        } else { 
          this.image_fileErrors.zonaBFile_msg = 'Al parecer tu imagen pesa mas de lo permitido'
          this.image_fileErrors.zonaBFile = true
        }
        this.$forceUpdate()
        return false
      }
      if  (validFormats===true && validFileSize===true){
        this.image_fileErrors.zonaFile_msg = ''
        this.image_fileErrors.zonaBFile_msg = ''
        this.image_fileErrors.zonaFile = false
        this.image_fileErrors.zonaBFile = false 
        // Cambiamos el estado del Componente a Vista de Editor o Actualizamos el Editor con la nueva imagen..! 

        if (typeof FileReader === 'function') {
          const reader = new FileReader();
          reader.onload = (event) => {

            this.imgzData.imgFileName = '' + fileDoc.name 
            this.imgzData.imgFileType = '' + fileDoc.type
            this.imgzData.imgSrc = event.target.result;
            // rebuild cropperjs with the updated source
            this.$refs.cropperzz.replace(event.target.result);
            this.cmp.state =1 
            this.$forceUpdate ()

          };
          reader.readAsDataURL(fileDoc);
        } else {
          alert('Lo sentimos, API de Lector de archivos no esta soportada, utilice otro explorador');
        }
        // var tmpImgData = {
        //   loaded: true,
        //   name: fileDoc.name,
        //   type: fileDoc.type,
        //   url: URL.createObjectURL(fileDoc),
        // }
        // Object.assign(this.imgxData, tmpImgData);

        this.$cannaDebug('-- dashMainScreen -- docs _ uploadNewFile -- finished')

        return true 
      } else {
        return false 
      }
    },
    //Acciones del editor
    cpx_resetImage(){
      this.$refs.cropperzz.reset();

    },
    cpx_reset() {
      this.cmp.state=0
      this.imgxfilex_doCancelCamera () //se reseta las props de la camara (si aplicaran para destruir el objeto)
      this.imgzData = {
        myDownloadFileName:'',
        imgFileName:'',
        imgFileType:'',
        imgSrc: '', //  images/login_warning.png
        cropImg: '',
        data: null,
        dataBlob:null
      }
      //-------------
      var mainInputElName = 'pac_fileinputx_' + this.internalId
      var mainEl = document.getElementById(mainInputElName);
      if (mainEl){
        mainEl.value = null 
      }
      this.$forceUpdate()
    },
    cpx_setDragMode(mode){ 
      this.$refs.cropperzz.setDragMode(mode);
    },
    cpx_DoCropImage(){ 
      var cropOptions =  {
        // width: 160,
        // height: 90,
        minWidth: 560,
        minHeight: 560,
        maxWidth: 1680,
        maxHeight: 1680,
        fillColor: '#fff',
        imageSmoothingEnabled: false,
        imageSmoothingQuality: 'high'
      }
      if (this.imgzData.imgFileType === 'image/jpeg'){ 
        this.imgzData.cropImg = this.$refs.cropperzz.getCroppedCanvas(cropOptions).toDataURL('image/jpeg');
      } else {
        this.imgzData.cropImg = this.$refs.cropperzz.getCroppedCanvas(cropOptions).toDataURL();
      }
      // Establecemos el paso 3 (para guardar la imagen y Subirla al server previo)
      this.cmp.state=2
      this.$forceUpdate()
    },
    cpx_zoom(percent) {
      this.$refs.cropperzz.relativeZoom(percent);
    },
    cpx_rotate(deg) {
      this.$refs.cropperzz.rotate(deg);
    },
    cpx_flipx(){
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute('data-scale');
      scale = scale ? -scale : -1;
      this.$refs.cropperzz.scaleX(scale);
      dom.setAttribute('data-scale', scale);
    },
    cpx_flipY(){
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute('data-scale');
      scale = scale ? -scale : -1;
      this.$refs.cropperzz.scaleY(scale);
      dom.setAttribute('data-scale', scale);
    },
    // Rutinas del Editor PAso final 
    cpxb_Cancel () {
      this.cmp.state=1
      this.$forceUpdate()
    },
    cpxb_Download () {
      //<a :id="'avatarfinal_' + internalId" target-ref="a_1" :download="imgzData.myDownloadFileName" style="display:none;"/>
      var test=11
      if (this.imgzData.cropImg==='' || this.imgzData.cropImg===null ) {
        return false
      }
      var avfileName = 'cannaAvatar_' + this.imgzData.imgFileName
      var myElinkId = 'avatarfinal_' + this.internalId;
      var myElLinkUse = document.getElementById(myElinkId)
      var myElLinkContents = this.imgzData.cropImg
      if(myElLinkUse!=null && myElLinkUse!=undefined){
        this.imgzData.myDownloadFileName = avfileName
        this.$forceUpdate()
        setTimeout(function(){
          myElLinkUse.href = myElLinkContents;
          myElLinkUse.click();
        },1500);
      }
    }, 
    cpxb_SaveSubmit () {
      this.$cannaDebug('-- photoComp -- cpxb _ Save Submit')
      var me = this 
      var test = 11
      // alert("subiendo imagen....!")// Enviamos el Emit con los datos de la imgen para q el componente padre lo procesece 
      var finalData = JSON.parse(JSON.stringify(this.imgzData))
      // ----------------------------
      var cropOptions = {
        // width: 160,
        // height: 90,
        minWidth: 560,
        minHeight: 560,
        maxWidth: 1680,
        maxHeight: 1680,
        fillColor: '#fff',
        imageSmoothingEnabled: false,
        imageSmoothingQuality: 'high'
      }
      if (this.imgzData.imgFileType === 'image/jpeg'){ 
        this.$refs.cropperzz.getCroppedCanvas(cropOptions).toBlob((blob) => {

          finalData.dataBlob = blob
          this.$emit('doSubmitPhotoAction', { data: finalData  })

        },'image/jpeg',1)
      } else { 
        this.$refs.cropperzz.getCroppedCanvas(cropOptions).toBlob((blob) => {

          finalData.dataBlob = blob
          this.$emit('doSubmitPhotoAction', { data: finalData  })

        },'image/png',1)
      }

    },
    //------------------------
    //navigator.mediaDevices  Related 
    async zmedia_checkSupport(){
      var test=11
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        //console.log("This browser does not support the API yet");
        this.zhasMedia_Support = false
        this.zhasMedia_Devices = false
      } else {
        this.zhasMedia_Support = true 
        //checar si tiene dispositivos de camara / video
        var countVideoDevices = 0
        await navigator.mediaDevices.enumerateDevices().then( (devices) => {
          devices.forEach((device) => {
            console.log(device.kind + ": " + device.label + " id = " + device.deviceId)
            if (device.kind === 'videoinput') {
              countVideoDevices+=1
            }
          });
        })
        .catch(function(err) {
          console.log(err.name + ": " + err.message)
          countVideoDevices = 0
        })
        if (countVideoDevices<=0){ 
          this.zhasMedia_Devices = false
        } else { 
          this.zhasMedia_Devices = true 
        }
      }
      test=22
      test=33
    },
    imgxfilex_doStartCamera (event) {
      this.cmp.state_fileReq = 'camera'
      setTimeout(() => {
        this.imgxfilex_doCaptureCamera()
      }, 300)
      this.$forceUpdate()
    },
    imgxfilex_doCaptureCamera (event) {
      
      var myintId = this.internalId
      var video = document.getElementById('pac_video_'  + myintId)
      var canvas = document.getElementById('pac_cameracanvas_'+ myintId)
      var photo = document.getElementById('pac_photocamera_'+ myintId)
      
      this.cameraData.errors = false
      this.cameraData.errorString = ''

      var videoConstraints= {video: true, audio: false}

      if (this.isSafariIos_client === false) {
        if (this.is_Mobile || this.$q.screen.lt.sm){
          var checkWidth = document.getElementById('pac_photoCapture_fotoZone').clientWidth
          this.cameraData.width = checkWidth
          //videoConstraints= {video: {facingMode: 'user'}, audio: false}
          videoConstraints= {video: true, audio: false}
        }
        video.setAttribute('autoplay', '');
        video.setAttribute('muted', '');
        video.setAttribute('playsinline', '');
      } else {
        videoConstraints= {video: true, audio: true}
      }

      navigator.mediaDevices.getUserMedia(videoConstraints).then((stream) => {
        video.srcObject = stream
        if (this.isSafariIos_client === true) {
          video.play()
        }
      }).catch((err) => {
        console.log("An error occurred: " + err);
        var errMsg = ''
        var testx = '' + err.name 
        if (testx === ''){
          errMsg = 'Error desconocido al accesar a camara/video'
        } else { 
          testx
          if (testx === 'NotAllowedError'){
            errMsg = 'Se ha denegado el uso de la camara !<br>Puedes cancelar y seleccionar un archivo ó recargar la página y permitir el acceso a la camara.'
          } else {
            errMsg = '' + err.message 
            if (errMsg==='Could not start video source'){
              errMsg = 'No es posible usar la camara, puede estar siendo usada en otro proceso.<br>Verifique y/o recarge la página para permitir el acceso a la camara.'
            }
          }
        }
        this.cameraData.errors =true 
        this.cameraData.errorString = errMsg
      });
      
      if (this.cameraData.errors === true ){ 
        return false 
      }

      video.addEventListener('canplay', (ev) => {
        if (!this.cameraData.streaming) {
         var height = video.videoHeight / (video.videoWidth / this.cameraData.width);
          // Firefox currently has a bug where the height can't be read from
          // the video, so we will make assumptions if this happens.
          if (isNaN(height)) {
            height =  this.cameraData.width / (4/3);
          }
          video.setAttribute('width', this.cameraData.width);
          video.setAttribute('height', height);
          canvas.setAttribute('width', this.cameraData.width);
          canvas.setAttribute('height', height);
          this.cameraData.height = height
          this.cameraData.streaming = true;
        }
      }, false);

      this.cameraData.videoEl = video 
      this.cameraData.canvasEl = canvas
      this.cameraData.photoEl = photo

    },
    imgxfilex_doCancelCamera (event) {
      this.cmp.state_fileReq = 'file'
      if (this.cameraData.videoEl!==null){

        try {

          let tracks = this.cameraData.videoEl.srcObject.getTracks();
          tracks.forEach(function (track) {
            track.stop();
          });
          tracks = this.cameraData.videoEl.srcObject.getVideoTracks();
          tracks.forEach( (track) => {
            track.stop();
            this.cameraData.videoEl.srcObject.removeTrack(track);
          });

        } catch (errcam){

        }

        this.cameraData.videoEl.pause()
        this.cameraData.videoEl.src = "";
        this.cameraData.videoEl.srcObject = null;
      }
      this.cameraData = {
        width: 440,
        height: 0,
        errors: false,
        errorString: '',
        data: null, 
        dataBlob:null,
        streaming:false ,
        streamEl: null,
        videoEl:null, 
        canvasEl:null,
        flagTaken: false ,
        photoEl:null,
      }
      this.$forceUpdate()
    },
    imgxfilex_doCameraTakePicture (event){
      if (this.cameraData.flagTaken === true ) {
        this.imgxfilex_doCameraClearphoto();
      } else { 
        var context = this.cameraData.canvasEl.getContext('2d');
        if (this.cameraData.width && this.cameraData.height) {
          this.cameraData.canvasEl.width = this.cameraData.width;
          this.cameraData.canvasEl.height = this.cameraData.height;
          //this.cameraData.canvasEl.translate(this.cameraData.width, 0); 
          context.translate(this.cameraData.width, 0); 
          context.scale(-1, 1);
          context.drawImage(this.cameraData.videoEl, 0, 0, this.cameraData.width, this.cameraData.height);
          var data = this.cameraData.canvasEl.toDataURL('image/jpeg',1);
          this.cameraData.photoEl.setAttribute('src', data);
          this.cameraData.photoEl.style.display = ''
          this.cameraData.flagTaken = true 
          this.cameraData.videoEl.style.display = 'none'
        } else {
          this.imgxfilex_doCameraClearphoto();
        }
      }

    },
    imgxfilex_doCameraAcceptImage (){
      console.log('aceptar imagen')
      var nameCameraPhotoFile = 'foto_camara_' + ''  + this.$moment().format('YYYY-MM-DD_HH-mm-ss') + '.jpg' //.format('DD/MM/YYYY')
      var dataImg = this.cameraData.canvasEl.toDataURL('image/jpeg',1);
      this.imgzData.imgFileName = '' + nameCameraPhotoFile // fileDoc.name 
      this.imgzData.imgFileType = 'image/jpeg' // + fileDoc.type
      this.imgzData.imgSrc = dataImg // event.target.result;
      // rebuild cropperjs with the updated source
      this.$refs.cropperzz.replace(dataImg);

      // Detenemos la camara
      if (this.cameraData.videoEl!==null){

        try {
        let tracks = this.cameraData.videoEl.srcObject.getTracks();
        tracks.forEach(function (track) {
          track.stop();
        });
        tracks = this.cameraData.videoEl.srcObject.getVideoTracks();
        tracks.forEach( (track) => {
          track.stop();
          this.cameraData.videoEl.srcObject.removeTrack(track);
        });
        } catch ( errcam ) { 
          
        }

        this.cameraData.videoEl.pause()
        this.cameraData.videoEl.src = "";
        this.cameraData.videoEl.srcObject = null;
      }

      this.cmp.state =1
      setTimeout(() => {
        //this.cmp.state_fileReq = 'file' //se resetea la vista de archivo (como video abort/cancel)
      }, 800)
      this.$forceUpdate ()
    },
    imgxfilex_doCameraCancelImage (){ 
      this.cameraData.videoEl.style.display = ''
      this.cameraData.photoEl.style.display = 'none'
      this.cameraData.photoEl.setAttribute('src', null);
      this.cameraData.flagTaken = false
    }, 
    imgxfilex_doCameraClearphoto (){
      var context = this.cameraData.canvasEl.getContext('2d');
      context.fillStyle = "#AAA";
      context.fillRect(0, 0, this.cameraData.canvasEl.width, this.cameraData.canvasEl.height);
      var data = this.cameraData.canvasEl.toDataURL('image/png');
      this.cameraData.photoEl.setAttribute('src', data);
    }
  },
  beforeCreate () {
    this.$cannaDebug('-- photoComp -- beforeCreate')
    this.zinitCmp = false
  },
  created () {
    this.$cannaDebug('-- photoComp -- created')
  },
  beforeMount () {
    // this.$cannaDebug('-- photoComp --  beforeMount')
    this.zmedia_checkSupport()
  },
  mounted () {
    this.$cannaDebug('-- photoComp -- mounted')
    //inicializar control 
    this.cmpx_initDragDropEvents()
    this.zinitCmp = true
  },
  beforeUpdate () {
    // this.$cannaDebug('-- photoComp -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- photoComp -- updated')
  },
  activated () {
    // this.$cannaDebug('-- photoComp -- activated')
  },
  deactivated () {
    // this.$cannaDebug('-- photoComp -- deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- photoComp -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- photoComp -- destroyed')
  }
}

</script>


