<template>
    <q-dialog 
      ref="dialogVEx"
      v-model="modalForm.show"
      content-class="cannaFormModal"
      transition-show="scale" 
      transition-hide="scale"
      :persistent="(is_Mobile || $q.screen.lt.sm)?true:false"
      :full-width="(is_Mobile || $q.screen.lt.sm)?true:false"
      :full-height="(is_Mobile|| $q.screen.lt.sm)?true:false"
      :no-esc-dismiss="(is_Mobile|| $q.screen.lt.sm)?false: ((modalForm.processing) ? true : false)"
      :no-backdrop-dismiss="(is_Mobile|| $q.screen.lt.sm)?false:true"
      @show="setShownDialog"
      @escape-key="doCloseDialog(true)"
    >
      <q-card class="canna_Formx " style="overflow: hidden;">
        <!-- encabezado -->
        <q-card-section horizontal class="q-card q-pa-md flex justify-between column noselect">
          <div class="closeButton">
            <cxIcon
              iconType="ui_close"
              size="24px"
              class=""
              :cls="'icon_close_svg'"
              :state="(modalForm.processing === false) ? 0 : 2"
              :hasMouseOver="true"
              :hasAction="true"
              :allowDisabled="true"
              :allowClick="true"
              @iconClick="doCloseDialog"
            />
          </div>
          <div class="form_title">
            <span>Ayuda</span>
          </div>
        </q-card-section>

        <q-separator />
        <!-- formulario -->
        <q-card-section  style="max-height: 85vh" class="canna_FormxContents scroll">
          <!-- Contenido del formulario -->
          <div>

            <div
              class="canna_forma_zona canna_formZone"
              style="margin-top: 0px;"
            >

              <div class="helpPhotoItem">
                <div class="col_icon">
                    <q-icon :size="'md'" class="" color="cannaMainColor" name="fas fa-arrows-alt"></q-icon>
                </div>
                <div class="col_help">
                   <div class="title"> Mover </div>
                   <div class="description">Al activar este icono podrás mover la imagen para ajustarla dentro del cuadro de contenido. </div>
                </div>
              </div>

              <div class="helpPhotoItem">
                <div class="col_icon">
                    <q-icon :size="'md'" class="" color="cannaMainColor" name="fas fa-crop"></q-icon>
                </div>
                <div class="col_help">
                   <div class="title"> Recortar </div>
                   <div class="description">Al activar este icono podrás hacer un recorte de la imagen que subiste. </div>
                </div>
              </div>

              <div class="helpPhotoItem">
                <div class="col_icon">
                    <q-icon :size="'md'" class="" color="cannaMainColor" name="zoom_in"></q-icon>
                </div>
                <div class="col_help">
                   <div class="title"> Acercar </div>
                   <div class="description">Con este icono podrás acercarte y hacer más grande tu imagen. </div>
                </div>
              </div>

              <div class="helpPhotoItem">
                <div class="col_icon">
                    <q-icon :size="'md'" class="" color="cannaMainColor" name="zoom_out"></q-icon>
                </div>
                <div class="col_help">
                   <div class="title"> Alejar </div>
                   <div class="description">Con este icono podrás alejarte y hacer más pequeña tu imagen. </div>
                </div>
              </div>

              <div class="helpPhotoItem">
                <div class="col_icon">
                    <q-icon :size="'md'" class="" color="cannaMainColor" name="rotate_left"></q-icon>
                </div>
                <div class="col_help">
                   <div class="title"> Girar a la izquierda </div>
                   <div class="description">Con este icono podrás girar tu imagen hacia la izquierda. </div>
                </div>
              </div>

              <div class="helpPhotoItem">
                <div class="col_icon">
                    <q-icon :size="'md'" class="" color="cannaMainColor" name="rotate_right"></q-icon>
                </div>
                <div class="col_help">
                   <div class="title"> Girar a la derecha </div>
                   <div class="description">Con este icono podrás girar tu imagen hacia la derecha. </div>
                </div>
              </div>

              <div class="helpPhotoItem">
                <div class="col_icon">
                    <q-icon :size="'md'" class="" color="cannaMainColor" name="swap_horiz"></q-icon>
                </div>
                <div class="col_help">
                   <div class="title"> Voltear horizontalmente </div>
                   <div class="description">Con este icono podrás voltear tu imagen de manera horizontal. </div>
                </div>
              </div>

              <div class="helpPhotoItem">
                <div class="col_icon">
                    <q-icon :size="'md'" class="" color="cannaMainColor" name="swap_vert"></q-icon>
                </div>
                <div class="col_help">
                   <div class="title"> Voltear verticalmente </div>
                   <div class="description">Con este icono podrás voltear tu imagen de manera vertical. </div>
                </div>
              </div>


              <div class="helpPhotoItem">
                <div class="col_icon">
                    <q-icon :size="'md'" class="" color="cannaMainColor" name="restart_alt"></q-icon>
                </div>
                <div class="col_help">
                   <div class="title"> Restaurar </div>
                   <div class="description">Con este icono podrás restaurar el editor al momento de seleccionar la imagen. </div>
                </div>
              </div>

              <div class="helpPhotoItem">
                <div class="col_icon">
                    <q-icon :size="'md'" class="" color="cannaMainColor" name="delete_forever"></q-icon>
                </div>
                <div class="col_help">
                   <div class="title"> Cancelar imagen </div>
                   <div class="description">Con este icono podrás eliminar el archivo de imagen que habías cargado y seleccionar otro. </div>
                </div>
              </div>

              <div class="helpPhotoItem">
                <div class="col_icon">
                    <q-icon :size="'md'" class="" color="cannaMainColor" name="done"></q-icon>
                </div>
                <div class="col_help">
                   <div class="title"> Aplicar cambios </div>
                   <div class="description">Este icono te permite guardar todos los cambios que hayas realizado en tu imagen. </div>
                </div>
              </div>


              <div class="helpPhotoItem">
                <div class="col_icon">
                    <q-icon :size="'md'" class="" color="cannaMainColor" name="block"></q-icon>
                </div>
                <div class="col_help">
                   <div class="title"> Regresar al editor </div>
                   <div class="description">Este icono te permite volver a la pantalla de herramientas para la edición de tu imagen. </div>
                </div>
              </div>

              <div class="helpPhotoItem">
                <div class="col_icon">
                    <q-icon :size="'md'" class="" color="cannaMainColor" name="download"></q-icon>
                </div>
                <div class="col_help">
                   <div class="title"> Descargar </div>
                   <div class="description">Con este icono podrás descargar a tu dispositivo la imagen que editaste. </div>
                </div>
              </div>

              <div class="helpPhotoItem">
                <div class="col_icon">
                    <q-icon :size="'md'" class="" color="cannaMainColor" name="save"></q-icon>
                </div>
                <div class="col_help">
                   <div class="title"> Guardar </div>
                   <div class="description">
                     Este icono te permite guardar la imagen en el primer paso del formulario 
                     <span>Información personal</span>. 
                  </div>
                </div>
              </div>

            </div>

          </div>


        </q-card-section>

        <!-- Loader de forma -->
        <q-inner-loading :showing="modalForm.processing">
          <q-spinner-orbit size="100px" color="indigo" />
        </q-inner-loading>

      </q-card>
    </q-dialog>
</template>

<script>
import logOut_Mixin from '../../mixins/requestMix.js'
import myplatform_Mixin from '../../mixins/platformMix.js'
import helperBase_Mixin from '../../mixins/helperBase.js'

export default {
  name: 'modal_photoHelp_form',
  props: {
    actionForm: { // add, edit, remove
      type: String,
      required: false,
      default: 'help'
    }
  },
  components: {
    cxIcon: require('components/appIcons.vue').default
  },
  mixins: [
    //helper_Mixin,
    logOut_Mixin,
    myplatform_Mixin,
    helperBase_Mixin
  ],
  data () {
    return {
      version: '1.0.1',
      // -----------------
      initFormFlag: false,
      processingData:false, 
      modalForm: {
        show: false,
        formErrors: 0,
        action: 'add', // // add,  edit , remove
        stepForm: 0, // 0 : subir video, 1:Detalles
        processing: false, 
      }
    }
  },
  computed: {
    show: {
      get: function () {
        return this.$parent.frm_helpFrmShow
      },
      set: function (value) {
        this.$parent.frm_helpFrmShow = value
      }
    },
    parentX () {
      return this.$parent
    }
  },
  watch: {
    initFormFlag: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$forceUpdate()
      }
    },
    'modalForm.show': function (newVal, oldVal) {
      if (newVal !== oldVal && newVal === true) {
        //this.modalForm.datesErrors.show = false 

      } else if (newVal !== oldVal && newVal === false) {
        // Se resetea la info del formulario 

        if(this.modalForm.processing === true ){
          this.modalForm.processing = false
        }
      }
      this.$forceUpdate()
    },
    'modalForm.processing': function (newVal, oldVal) {
      this.$forceUpdate()
    }
  },
  methods: {
    doShow () {
      this.modalForm.show = true
    },
    doHide () {
      this.modalForm.show = false
    },
    setShownDialog () {
      this.$cannaDebug('-- photoHelpModal -- set Shown Dialog')
      // ---------------------
      //this.modalForm.stepForm = this.modalidad // ETapa o eventos 
      this.modalForm.action = this.actionForm
      //this.modalForm.datesErrors.show = false // Forzar al que el mensaje de fechas no aparezca 
      setTimeout(() => {
        this.onReset()
        // acciones para el  formulario 

        this.initFormFlag = true
        this.$forceUpdate()
      }, 200)
    },
    doCloseFinishDialog () { 
      this.initFormFlag = false
      this.onReset()
      this.$cannaDebug('-- photoHelpModal -- doClose Finish Dialog')
      this.$emit('doCloseDialog', { newState: false, finish:true })
      this.modalForm.show = false
    },
    doCloseDialog (checkProcessing) {
      if (checkProcessing !== undefined) {
        if (this.modalForm.processing === true) {
          return
        }
      }
      this.initFormFlag = false
      this.onReset()
      this.$cannaDebug('-- photoHelpModal -- do Close Dialog')
      this.$emit('doCloseDialog', { newState: false })
      this.modalForm.show = false
    },
    onReset () {
      this.modalForm.formErrors = 0
      this.modalForm.stepForm = 0


      this.$forceUpdate()
    },
    // ---------------
    // ---------------
    onSubmit: async function () {
      this.$cannaDebug('-- photoHelpModal -- onSubmit')
      if (this.mainErrors > 0) {
        return false
      }
      // ------------ Continuamos 
      return true
    }
    // ----------------------------
  },
  beforeCreate () {
    this.$cannaDebug('-- photoHelpModal -- beforeCreate')
    this.initFormFlag = false
  },
  created () {
    this.$cannaDebug('-- photoHelpModal -- created')
    //this.$q.iconSet.field.error = 'r_report_problem'
  },
  beforeMount () {
    // this.$cannaDebug('-- photoHelpModal --  beforeMount')
  },
  mounted () {
    this.$cannaDebug('-- photoHelpModal -- mounted')
    this.$forceUpdate()
    //this.initFormFlag = true
  },
  beforeUpdate () {
    // this.$cannaDebug('-- photoHelpModal -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- photoHelpModal -- updated')
  },
  activated () {
    // this.$cannaDebug('-- photoHelpModal -- activated')
  },
  deactivated () {
    // this.$cannaDebug('-- photoHelpModal -- deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- photoHelpModal -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- photoHelpModal -- destroyed')
  }
}
</script>
