<template>
  <div id="psi_pagecontainer_master" class="ppi_pageZone_container">

    <div class="ppi_pz_header noselect">
      <div class="pageZone_title">
        Siempre es útil ponerle rostro a un nombre
      </div>
      <div class="pageZone_subtitle spacerRowTop24">
        Sube una foto tuya, ¡la que más te guste! La usaremos para adjuntarla en tu expediente.
      </div>
    </div>

    <div class="ppi_contentZone">
      <div class="formDetailZone">

        <div class="dataSection_container">
          <template v-if="zinitScreen===true">

            <photoAvatar 
              v-show="(currentState===0)"
              :internalId="'profileAvatarDat'"
              :hasOtherRecord="userHasExp_Photo"
              :autoStart="true"
              :options_allowDD="photo_settings.canDD"
              :options_uploadTextLegend="photo_settings.text_Legend_DD"
              :options_uploadTextNO_DD_Legend="photo_settings.text_Legend_NODD"
              :options_uploadTextFormats="'JPG, PNG'"
              v-on:doSubmitPhotoAction="doAction_SubmitPhotoContents"
              v-on:doAbortChangePhoto="doAction_abortChangePhoto"
            >
            </photoAvatar>

            <template v-if="(currentState===1 && userHasExp_Photo===true)">
              <!-- Zona de vista previa de FOTO e indicador de Archivo -->
                <div class="common_docs_container">
                    <div class="photoFilePreviewZone_Photo spacerRowTop24">
                      <div style="max-width: 260px;">
                        <img :src="photo_getImagUrl()" style="max-width: 100%;max-height: 100%;">
                      </div>
                    </div>
                    <div class="attachbox spacerRowTop16 noselect" style="display: flex;flex-direction: row;align-items: center;">
                        <div>
                          <cxIcon
                            iconType="cannav2_docs_attachment"
                            size="40px"
                            class=""
                            :cls="''"
                            :overCls="''"
                            :state="0"
                            :hasMouseOver="false"
                            :hasAction="false"
                            :allowDisabled="false"
                            :allowClick="false"
                            style="margin-right: 10px;"
                          />
                        </div>
                        <div style="margin-left: 8px;">
                          <div class="txt_label">Archivo adjunto:</div>
                          <div class="txt_filename action_item review_link" style="overflow-wrap: anywhere;" @click.stop="photo_doOpenFile()">
                            {{current_profile.imagen}}
                          </div>
                        </div>
                    </div>
                    <div class="noselect moreUploads spacerRowTop24" style="flex-direction: column;">
                      <div class="action_item doc_uploadLink" @click.stop="photo_changeImage()">
                        Subir un nuevo archivo
                      </div>
                    </div>
                </div>
            </template>

          </template>
        </div>

      </div>
    </div>

    <div class="ppi_pz_footer">
      <div class="rowButtons_initial">
        <div style="display: flex;flex-direction: column;">
          <div>
            <q-btn color="app_mainBtn" icon-right="r_chevron_right" label="Siguiente" no-caps unelevated
                @click.stop="doSubmitData"
                :disable="(userHasExp_Photo===false) ? true : false"
            />
          </div>
          <template v-if="qa_allowReviewLink===true">
            <div style="display:flex;">
              <div class="link_actionTextAlt action_item spacerRowTop16" @click.stop="goTo_PreviewPage">
                Ir a mi resumen
              </div>
            </div>
          </template>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { requestPack } from './../../../../boot/axios' // APP code 
import logOut_Mixin from './../../../../mixins/requestMix.js'
import myplatform_Mixin from './../../../../mixins/platformMix.js'
import helperBase_Mixin from './../../../../mixins/helperBase.js'
import questionario_Mixin from './../../../../mixins/questionario/questionario_base.js'

export default {
  name: 'psi_screen_foto',
  mixins:[
    helperBase_Mixin,
    logOut_Mixin,
    myplatform_Mixin,
    questionario_Mixin
  ],
  components:{
    cxIcon: require('components/appIcons.vue').default,
    photoAvatar: require('components/photov2/photoAvatar.vue').default
  },
  data () { 
    return {
      version: '1.0.1',
      debugEls: false,
      zinitScreen: false,
      zloadingData: false,
      processingData: false,
      //-------------------
      userHasExp_Photo: false,
      currentState: 0, // 0 Vista de componente Upload , 1 = vista de Archivo PHOTO 
      photo_settings:{ 
        canDD: true,
        text_Legend_DD: 'Arrastra y suelta aquí una imagen o elige un archivo de tu dispositivo.', //'Elige un archivo de tu dispositivo.'
        text_Legend_NODD: 'Elige un archivo de tu dispositivo.',
        text_formats: 'JPG, PNG',
      }
    }
  },
  computed: {
    current_profile (){
      return this.getProfileData
    },
  },
  watch: {
    is_Mobile: function( newValue, oldValue) { 
      if (newValue!== oldValue){ 
        if (newValue==='xs' || newValue==='sm'){ 
          this.photo_settings.canDD=false
        } else { 
          this.photo_settings.canDD=true
        }
      }
    },
    pageSize : function( newValue, oldValue) { 
      if (newValue!== oldValue){ 
        if (newValue==='xs' || newValue==='sm'){ 
          this.photo_settings.canDD=false
        } else { 
          this.photo_settings.canDD=true
        }
      }
    },
    'photo_settings.canDD':function( newValue, oldValue) { 
      if (newValue!== oldValue){ 
        if (newValue === true ){ 
          this.photo_settings.text_Legend =  'Arrastra y suelta aquí una imagen o elige un archivo de tu dispositivo.'
        } else { 
          this.photo_settings.text_Legend =  'Elige un archivo de tu dispositivo.'
        }
      }
    },
  },
  methods: {
    ...requestPack,
    test () { 
      this.$cannaDebug('-- psiPage_foto --  test')
    },
    showLoadx ( value, type ){
      // type: 1 orbit -- 2 gears 
      var tpuse = (type===undefined || type===null || type==='') ? 2 : (type === 1 || type === 2) ? type : 2 
      this.$parent.showLoadr(tpuse , value )
    },
    photo_doOpenFile() {
      this.$cannaDebug('-- psiPage_foto -- doOpen File')
      var url = ''
      if (this.current_profile._links['file-stream'].href.indexOf('?')>=0){
        url= '' + this.current_profile._links['file-stream'].href + '&accessToken=' + this.$store.state.myapp.appUserData.access_token
      } else { 
        url= '' + this.current_profile._links['file-stream'].href + '?accessToken=' + this.$store.state.myapp.appUserData.access_token
      }
      // Abrir en una nueva ventana 
      window.open(url)
    },
    photo_getImagUrl() {
      this.$cannaDebug('-- dashMainScreen -- docs _ get PDF Url')
      var url = ''
      if (this.current_profile._links['file-stream'].href.indexOf('?')>=0){
        url= '' + this.current_profile._links['file-stream'].href + '&accessToken=' + this.$store.state.myapp.appUserData.access_token
      } else { 
        url= '' + this.current_profile._links['file-stream'].href + '?accessToken=' + this.$store.state.myapp.appUserData.access_token
      }
      return url
    },
    photo_changeImage () { 
      this.$cannaDebug('-- psiPage_foto -- photo _ change Image')
      this.currentState = 0
      this.$forceUpdate ()
    },
    doAction_abortChangePhoto (payload){
      if(this.userHasExp_Photo ===true){
        this.currentState = 1
        this.$forceUpdate()
      }
      return true 
    },
    async doAction_SubmitPhotoContents (payload){
      this.$cannaDebug('-- psiPage_foto --  Submit Photo Contents --- ')
      /*
            payload.data = {
              myDownloadFileName:'',
              imgFileName:'',
              imgFileType:'',
              imgSrc: '', //  images/login_warning.png
              cropImg: '',
              data: null,
              dataBlob:null
            }
      */
      var me = this
      var test = 11
      this.zflag_uploading = true //Para controlar q no suban otro archivo en el inter 
      var mcModel = this.getModelRequest('userProfile')
      var reqRes = { action: [], filex: [], docRM: [], profilex:[] }
      var reqErr = { action: 0, filex: 0, docRM: 0, profilex:0 }

      this.showLoadx(true, 1)

      var myformData = null
      myformData = new FormData();

      myformData.append('file', payload.data.dataBlob, payload.data.imgFileName);
      myformData.append('imagen', '');

      var myPhotoConfig = this.createAxiosConfig ('patch', mcModel, 
        { useData:true, isFileUpload: true, formData: myformData }, true, null
      )
      // myPhotoConfig.onUploadProgress = function (progressEvent) {
      //     let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      //     docItem._uploadingProgress = percentCompleted / 100 
      //     me.$forceUpdate()
      //     console.debug('DEMO --- onUploadProgress called with', arguments, 'Percent Completed:' + percentCompleted + '----' + DemoStr)
      // }
      test = 22

      await this.doRequestByPromise(this.$apiV2, 'profDocs', reqRes.action, myPhotoConfig).then((resx) => {
        // console.log ('Action -- Carga de Docs -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      //-----------------------
      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        this.ex_handleErrorRequest (reqRes.action[0], reqErr.action)
        this.showLoadx(false, 1)
        return false 
      }
      // -----------------------
      await this._waitRequestProc(500)
      // Si todo pasa bien ( Actualizamos los datos de profile del usuario...! )
      // Necesitamos actualizar los datos de profile de la persona...! 
      var myProfileDataConfig = this.createAxiosConfig ('get', mcModel, { } , true, null)
      await this.doRequestByPromise(this.$apiV2, 'profile', reqRes.profilex, myProfileDataConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.profilex[reqRes.profilex.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.profilex += 1
        }
      })
      if (reqErr.profilex > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 44
        this.ex_handleErrorRequest (reqRes.profilex[0], reqErr.profilex)
        this.showLoadx(false, 1)
        return false 
      }
      var newProfileData = reqRes.profilex[0].data.data // Los datos actualizados del profile
      var fieldsG = ['imagen', '_links']
      var newConfigVals = {}
      test = 100

      for (const campoG of fieldsG) {
        var campoApi = campoG
        newConfigVals[campoApi] = newProfileData[campoApi]
      }

      test = 200

      this.$store.commit('myapp/setAppUserProfileData', newConfigVals)
      this.zinitScreen = false 
      await this._waitRequestProc(2000)
      
      var respInitData = null
      await this.initFormData().then( respx => {
        respInitData = respx 
      })

      this.zinitScreen = true
      await this._waitRequestProc(1000)

      this.showLoadx(false, 1)
      return true
    },
    async doSubmitData () {
      this.$cannaDebug('-- psiPage_foto --  do Submit Data --- ')
      // Actualizar Datos de Estatus // WIP 
      var retUPS = null
      if (this.currProfile_status < this.zstatUser.FOTO ){ // this.zstatUser.FOTO = 13 
        await this.hpb_updateProfileStatus( this.zstatUser.FOTO ).then(respx=>{
          retUPS = respx
        })
      }
      // ----------
      var newRuta = '/dashboard/personal/trabajo-b'
      this.$router.push(newRuta)
    }, 
    async initFormData(){
      var testImagenName = this.current_profile.imagen
      if ( testImagenName===null || testImagenName=='' || testImagenName==undefined){ 
        this.currentState = 0 
        this.userHasExp_Photo = false
      } else { 
        var testFile = '' + this.current_profile._links['file-stream'].href
        if (testFile!==undefined && testFile!==null &&  testFile!==''){
        // if (testImagenName.startsWith('expediente_') === true &&  testFile!==''){
          this.currentState = 1 
          this.userHasExp_Photo = true
        } else { 
          this.currentState = 0 
          this.userHasExp_Photo = false 
        }
      }
      await this._waitRequestProc(2000)
      return true 
    }
  },
  beforeCreate () {
    this.$cannaDebug('-- psiPage_foto --  beforeCreate')
  },
  created () {
    this.$cannaDebug('-- psiPage_foto --  created')
    this.zloadingData = true 
    this.zinitScreen = false
    this.$q.iconSet.field.error = 'r_report_problem'
  },
  beforeMount () {
    // this.$cannaDebug('-- psiPage_foto --   beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- psiPage_foto --  mounted')
    this.zloadingData = false
    this.showLoadx(true, 2)

    if ((this.is_Mobile || this.$q.screen.lt.sm)){ 
      this.photo_settings.canDD = false 
    }

    await this._waitRequestProc(200)
    var respInitData = null
    await this.initFormData().then( respx => {
      respInitData = respx 
    })

    await this._waitRequestProc(500)
    this.$forceUpdate()

    this.zloadingData = false
    this.showLoadx(false, 2)
    this.zinitScreen = true
  },
  beforeUpdate () {
    // this.$cannaDebug('-- psiPage_foto --  beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- psiPage_foto --  updated')
  },
  async activated () {
    this.$cannaDebug('-- psiPage_foto --  activated')
    if (this.zinitScreen === true){ 
      this.zloadingData = true
      this.zinitData = false
      this.showLoadx(true, 1)

      var respInitData = null 
      await this.initFormData().then( respx => {
        respInitData = respx 
      })
      await this._waitRequestProc(1000)

      this.zloadingData = false
      this.showLoadx(false, 1)

      this.zloadingData = false
    }
  },
  deactivated () {
    // this.$cannaDebug('-- psiPage_foto --  deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- psiPage_foto --  beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- psiPage_foto --  destroyed')
  }
}
</script>
